





























import Vue from "vue";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import { TradeshowCompanyObject } from "@/types/interfaces";
import MgImage from "@/components/shared/MgImage.vue";

export default Vue.extend({
    data() {
        return {
            swiperOption : {
                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,
                autoplay: {
                    delay: 2000,
                    disableOnInteraction: true
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    1336: {
                        slidesPerView: 5,
                        spaceBetween: 10
                    }
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                }
            }
        }
    },
    props: {
        data: {
            type: Object as () => Array<TradeshowCompanyObject>,
            default: () => [],
            required: true
        }
        // @Prop({
        //     required: true,
        //     default: () => []
        // })
        // data!: Array<TradeshowCompanyObject>;
    },
    components: {
        Swiper,
        SwiperSlide,
        MgImage
    },
    computed: {
        pageConfig(): Record<string, string> {
            return this.$store.getters.getPageOptions("tradeshow") || {};
        },
    
        sortedData(): Array<TradeshowCompanyObject> {
            return this.sortedDataMethod();
        }
    },
    methods: {
        sortedDataMethod() {
            return this.data
                .sort((a: TradeshowCompanyObject, b: TradeshowCompanyObject) => {
                    const aName = a.companyName ? a.companyName.toLowerCase() : "";
                    const bName = b.companyName ? b.companyName.toLowerCase() : "";
                    return aName.localeCompare(bName);
                })
                .sort((a: TradeshowCompanyObject, b: TradeshowCompanyObject) => {
                    const aLevel = a.level ? parseInt(a.level) : 0;
                    const bLevel = b.level ? parseInt(b.level) : 0;
    
                    return bLevel - aLevel;
                });
        },

        getLogoImage(exhibitor: TradeshowCompanyObject) {
            let returnPath = exhibitor.imageLogo || exhibitor.images?.logo;
            if (!returnPath) {
                return (returnPath = `/bg/${this.pageConfig.defaultLogo}`);
            } else {
                return returnPath;
            }
        },
    
        getImgPath(exhibitor: TradeshowCompanyObject) {
            const logo = exhibitor?.images?.logo;
            let returnPath = "";
            if (logo && "string" === typeof logo) {
                returnPath = logo;
            } else if (exhibitor.imageLogo) {
                returnPath = exhibitor.imageLogo;
            } else {
                returnPath = `/bg/${
                    this.$store.getters.getPageOptions("tradeshow").defaultLogo
                }`;
            }
    
            return returnPath;
        },
        getImgSrcset(exhibitor: TradeshowCompanyObject) {
            let returnPath = "";
            const set = [];
    
            if (exhibitor?.images?.logo) {
                const urls = exhibitor.images.logo;
                const img400 = urls["400x400"];
                const img800 = urls["800x800"];
    
                if (img400) {
                    set.push(`${img400} 600w`);
                }
    
                if (img800) {
                    set.push(`${img800} 2048w`);
                }
    
                returnPath = set.join(",");
            }
    
            return returnPath;
        }
    }
})
